"use strict";
const axios = require("axios");
const store = require("../store");

function get() {

	const accessToken = store.getStore().getState().base.access_token;
	const headers = {
		"X-EFC-TIMEOFFSET": (new Date()).getTimezoneOffset()
	};

	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`;
	}

	const agent = axios.create({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN,
		headers,
		timeout: 600000 // 30 seconds
	});

	agent
		.interceptors
		.response
		.use(
			null,
			function (error) {
				if (error.code == "ECONNABORTED") {
					error.message = "Request timeout after 1 minutes. Please try again or contact IT item for that issue.";
				} else {
					const serverMessage = error?.response?.data?.error?.message;
					if (serverMessage) {
						error.message = serverMessage;
					}
				}
				return Promise.reject(error);
			}
		);

	return agent;
}

module.exports.get = get;
