"use strict";
const agent = require("./agent");

function estimateProfitMargin(platforms, codes) {
	return agent
		.get()
		.post("/api/price/profit-margin/estimate", { platforms, codes })
		.then(response => response.data.data);
}

function getAdvises(queries) {
	return agent
		.get()
		.get("/api/price/advise", { params: queries })
		.then(response => response.data);
}

function update(updates) {
	return agent
		.get()
		.patch("/api/price", { payload: updates })
		.then(response => response.data);
}

function requestExportEstimation(request) {
	return agent
		.get()
		.post("/api/price/estimation/export", { request })
		.then(response => response.data.data);
}

function getExportStatus(payload) {
	return agent
		.get()
		.get("/api/price/estimation/export", { params: payload })
		.then(response => response.data);
}

function deleteEstimateRequest(id) {
	return agent
		.get()
		.delete("/api/price/estimation/export/" + id)
		.then(response => response.data);
}

function getNewPriceAdvisorData(params) {
	return agent
		.get()
		.post("/api/price/advisor/new", {params})	
		.then(response => response.data);
}

function getPlatformFromPriceTable(params) {
	return agent
		.get()
		.get("/api/price/advisor/new/salesChannel", {params})	
		.then(response => response.data);
}

function ExportContributionMargin(params) {
	return agent
		.get()
		.post("/api/price/advisor/new/export",{params},{ responseType: "blob"})
		.then(response => response.data);
}

function getDetailAndImage(params) {
	return agent
		.get()
		.get("/api/price/advisor/getDetailAndImage", {params})	
		.then(response => response.data);
}

function getSellingPriceRate(params) {
	return agent
		.get()
		.get("/api/price/advisor/getSellingPriceRate", {params})	
		.then(response => response.data);
}

function requestSignUrlWithBucket(params) {
	return agent
		.get()
		.post("/api/price/amazonPriceUpdate/getSignUrlWithBucket", {params})	
		.then(response => response.data);
}

function insertIntoList(params) {
	return agent
		.get()
		.post("/api/price/amazonPriceUpdate/insertIntoList", { params })
		.then(response => response.data);
}

function getAmzPriceUpdateStatusList(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/getAmzPriceUpdateStatusList", { params })
		.then(response => response.data);
}

function getUploadingUserList(payload) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/getUploadingUserList", { payload })
		.then(response => response.data);
}

function getProductPriceImportErrors(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/getProductPriceImportErrors", { params })
		.then(response => response.data);
}

function getAmzPriceList(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/getAmzPriceList", { params })
		.then(response => response.data);
}

function ExportAmzPriceList(params) {
	return agent
		.get()
		.post("/api/price/amazonPriceUpdate/ExportAmzPriceList/",{params},{ responseType: "blob"})
		.then(response => response.data);
}

function UpdateAmzPriceList(params) {
    return agent
		.get()
		.post("/api/price/amazonPriceUpdate/UpdateAmzPriceList/",{params})
		.then(response => response.data);
}

function getPortfolioAndBrands(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/PortfolioAndBrands", {params})	
		.then(response => response.data);
}

function getAmzPriceHistory(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/getAmzPriceHistory", {params})	
		.then(response => response.data);
}

function getSellingPriceRateTargetAndWalmart(params) {
	return agent
		.get()
		.post("/api/price/advisor/new/getSellingPriceRateTargetAndWalmart", { params })
		.then(response => response.data);
}

function amazonPriceUpdateApproval(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/amazonPriceUpdateApproval", {params})	
		.then(response => response.data);
}

function approvalSubmit(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/approvalSubmit", { params })
		.then(response => response.data);
}

function getPriceQueueStatus(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/getPriceQueueStatus", { params })
		.then(response => response.data);
}

function getAmazonPriceQueueSelections(params) {
	return agent
		.get()
		.get("/api/price/amazonPriceUpdate/getAmazonPriceQueueSelections", { params })
		.then(response => response.data);
}

module.exports = {
	update,
	getAdvises,
	estimateProfitMargin,
	requestExportEstimation,
	getExportStatus,
	deleteEstimateRequest,
	getNewPriceAdvisorData,
	getPlatformFromPriceTable,
	ExportContributionMargin,
	getDetailAndImage,
	getSellingPriceRate,
	requestSignUrlWithBucket,
	insertIntoList,
	getAmzPriceUpdateStatusList,
	getUploadingUserList,
	getProductPriceImportErrors,
	getAmzPriceList,
	ExportAmzPriceList,
	UpdateAmzPriceList,
	getPortfolioAndBrands,
	getAmzPriceHistory,
	getSellingPriceRateTargetAndWalmart,
	amazonPriceUpdateApproval,
	approvalSubmit,
	getPriceQueueStatus,
	getAmazonPriceQueueSelections
};
